















































































































import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { DataOptions } from "vuetify";
import { v4 as uuid } from "uuid";

export default defineComponent({
  setup(_, { root }) {
    const state = reactive({
      singleSelect: false,
      selected: [],
      expanded: [],
      defaultSort: "order",
      headers: [
        {
          value: "order",
          text: root.$tc("layout.misc.orderNumber"),
          sortable: false,
          width: 1,
        },
        {
          value: "name",
          text: root.$tc("layout.misc.name"),
          groupable: false,
          sortable: false,
        },
        {
          value: "state",
          text: root.$tc("layout.misc.status"),
          groupable: false,
          align: "end",
          sortable: false,
        },
        {
          value: "actions",
          text: "",
          groupable: false,
          align: "end",
          width: 1,
          sortable: false,
        },
      ],
      empty: false,
      items: [],
      table: false,
      loading: false,
      loaded: false,
      total: 0,
      options: {
        sortBy: ["order"],
        sortDesc: [false],
      },
      deleteGroup: false,
      currency: "",
      error: false as boolean | number,
    });

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 400:
          return `${root.$tc("layout.errors.400")}`;
        case 401:
          return `${root.$tc("layout.errors.401")}`;
        case 404:
          return `${root.$tc("layout.errors.404")}`;
        case 406:
          return `${root.$tc("layout.errors.cannotRemoveParticipantFee")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    // Start fetch registration fees

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;
      const { sortBy, sortDesc } = (state.options as unknown) as DataOptions;

      state.loading = true;

      axiosInstance
        .get(`/event/${root.$route.params.id}/registration-fee/group`, {
          params: { sortBy, sortDesc },
        })
        .then(({ data }) => {
          state.empty = false;
          state.items = data.map((group: any) => ({
            ...group,
            registrationFees: group.registrationFees
              .map((fee: any) => ({
                ...fee,
                registrationFeeTerms: fee.registrationFeeTerms.sort(
                  (a: any, b: any) => {
                    return b.order - a.order;
                  }
                ),
              }))
              .sort((a: any, b: any) => {
                return a.order - b.order;
              }),
          }));
          console.log(state.items);
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.empty = true;
            state.items = [];
            state.total = 0;
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchData);

    // End fetch registration fees

    watch(() => state.options, fetchData, { deep: true });

    const deleteGroup = (group: { id: string }) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .delete(`/registration-fee/group/${group.id}`, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          state.deleteGroup = false;
          fetchData();
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    const deleteFee = (item: { id: string; deleteDialog: boolean }) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .delete(`/registration-fee/${item.id}`, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          item.deleteDialog = false;
          fetchData();
        })
        .catch((error) => {
          state.error = error.response.status;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: getErrorMessage(state.error as number),
          });
        })
        .finally(() => (state.loading = false));
    };

    // Fetch event

    const fetchEvent = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}`)
        .then(({ data: { event } }) => {
          state.currency = event.currency;
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchEvent);

    const registeredValue = (items: any) => {
      const sum = items.reduce((acc: any, obj: any) => {
        return acc + obj.participantRegistrationFeeTerms;
      }, 0);
      return sum;
    };

    return { state, fetchData, deleteGroup, deleteFee, registeredValue };
  },
});
